@import "node_modules/font-awesome/scss/font-awesome.scss";
@import "bower_components/simple-line-icons/scss/simple-line-icons.scss";
@import "node_modules/bootstrap/dist/css/bootstrap";
@import "bower_components/jquery.uniform/src/scss/default.scss";
@import "resources/assets/metronic_template/layouts/layout4/css/layout";
@import 'resources/assets/metronic_template/layouts/layout4/css/themes/light';
@import 'resources/assets/metronic_template/assets/layouts/layout4/css/custom';
@import 'resources/assets/metronic_template/assets/global/css/components-rounded';
@import 'resources/assets/metronic_template/assets/pages/css/login-3';
@import 'resources/assets/metronic_template/assets/global/plugins/jquery-ui/jquery-ui.min';
@import 'resources/assets/metronic_template/assets/global/css/plugins';
@import 'bower_components/bootstrap-touchspin/src/jquery.bootstrap-touchspin';
@import 'bower_components/datatables/media/css/dataTables.bootstrap';
// @import 'node_modules/datatables.mark.js/dist/datatables.mark.min';
@import 'bower_components/select2/dist/css/select2';
@import 'node_modules/select2-bootstrap-theme/dist/select2-bootstrap.min';
@import 'bower_components/webui-popover/dist/jquery.webui-popover';
@import 'node_modules/dropzone/dist/dropzone';
@import 'node_modules/summernote/dist/summernote';
@import 'bower_components/toastr/toastr';
@import 'bower_components/bootstrap-tabdrop/build/css/tabdrop';
@import 'bower_components/bootstrap-datepicker/dist/css/bootstrap-datepicker3';
@import 'resources/assets/custom/js/jonthornton-jquery-timepicker-3f26316/jquery.timepicker';
@import 'resources/assets/css/custom';

/*input fields label styles*/
.control-label {
    margin-top: 1px;
    font-weight: 600;

}

@media(min-width: 768px) and (max-width:1400px){    
    .multiline_label {
        margin-top: -7px!important;
     }
}
             

/*/*label in mulltiple lines */
@media (min-width:970px) and (max-width: 1400px){
        .triple_line_label {
             margin-top: -16px;
    }
}

// .checkbox input[type=checkbox],
// .checkbox-inline input[type=checkbox],
// .radio input[type=radio],
// .radio-inline input[type=radio] {
// /*margin-left: -20px;*/ 
// }

 /*Tabs count badges*/
    .badge {
    font-size: 12px!important;
    font-weight: 600;
    }
    
    .badge-primary {
    background-color: rgb(105, 160, 210);
    }
    pills>li>a>.badge, .nav-tabs>li>a>.badge {
        margin-top: -4px;
        
    }
    /*end badges*/


/*Tabs NUMBERING IN MODE*/
// .nav-tabs>li>a {
    /*padding-right: 10px;*/
    /*padding-left: 10px;*/
// }
.crm-tab-nav>li>a span {
    top: 0px;
    padding-right: 0px;
    padding-left: 5px;
    color: rgb(198, 209, 229);
    font-size: 11px;
    /*position: absolute;*/
    text-align: center;
}
 .crm-tab-nav>li>a span:before{content:"("}
 .crm-tab-nav>li>a span:after{content:")"}

/*when tabs hidden in drop*/
 .dropdown-menu>li>a span:before{content:"("}
.dropdown-menu>li>a span:after{content:")"}
.dropdown-menu>li>a span {
   
    right: 3px;
    color: rgb(198, 209, 229);
    font-size: 11px;
    position: absolute;
    text-align: center;
    
}
// NOTES
  /*notes*/
.timeline .timeline-body-time {
    font-size: 12px;
}
.timeline .timeline-body-content {
    font-size: 13px;
}
.page-head .page-title>h1 {
    font-size: 20px;
}
h3{
    font-size: 20px;
}
h2 {
    font-size: 24px;
}
h1 {
    font-size: 22px;
}
.portlet>.portlet-title>.caption {
    font-size: 16px;
}
.timeline .timeline-body-alerttitle, .timeline .timeline-body-title {
    font-size: 14px;
}
.nav>li>a:focus, .nav>li>a:hover {
    background-color: transparent;
}
// .btn.light-gray:not(.btn-outline):hover {
//     background-color: #ccc;
// }
.btn.light-gray{
    color: #333;
    background-color: #fff;
    border-color: #ccc;
}

/*pharentheses in drop*/
/*END NUMBERING*/
mark, .mark{
    background: orange;
    padding:0!important;
}

//header to be responsive on phones
.page-header.navbar .page-top {
    height: auto;
}
div.top-menu > ul.nav{
    display: flex;
    flex-wrap: wrap;
}
// .btn
.modal-body .form-group {
    margin-right: unset;
    margin-left: unset;
}
//fix summernote modal visual bugs
.modal-body .form-group .checkbox input[type=checkbox]{
    margin-left:-20px!important;
}

.modal-body .checkbox{
    margin-left: 20px!important;
}

.text-right{
    text-align: right;
}
.text-left{
    text-align: left;
}
.text-center{
    text-align: center;
}